import flag_nl from "../assets/images/flags/nl.svg";
import flag_de from "../assets/images/flags/de.svg";
import flag_be from "../assets/images/flags/be.svg";
import flag_en from "../assets/images/flags/int.svg";
import flag_fr from "../assets/images/flags/be.svg";
import flag_ch from "../assets/images/flags/ch.svg";
import flag_int from "../assets/images/flags/int.svg";
import flag_at from "../assets/images/flags/at.svg";
// import map_options from "../assets/json/mapOptions.json";
const PUBLIC_WEBSITE = process.env.VUE_APP_WEBSITE;
export default {
  data: () => ({
    debug: true,
    isAppMobile: false,
    isAppTablet: false,
    isAppDesktop: false,
    defaultLanguage: "en",
    staticBlocksObject: "li_static_blocks",
    pageObject: "li_pages",
    itemObject: "li_portfolio",
    flags: {
      at: flag_at,
      be: flag_be,
      be_fr: flag_be,
      de: flag_de,
      nl: flag_nl,
      en: flag_en,
      fr: flag_fr,
      ch: flag_ch,
      int: flag_int,
    },
    scrollPosition: 0,
    website: PUBLIC_WEBSITE,
    // mapOptions: map_options,
    fullUrl: null,
  }),
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
    window.removeEventListener("scroll", this.updateScroll);
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    window.addEventListener("scroll", this.updateScroll);
  },
  watch: {
    language(val) {
      if (val == "be_fr") {
        document.documentElement.setAttribute("lang", "FR");
      } else if (val == "be") {
        document.documentElement.setAttribute("lang", "NL");
      } else if (val == "ch") {
        document.documentElement.setAttribute("lang", "DE");
      } else if (val == "at") {
        document.documentElement.setAttribute("lang", "DE");
      } else {
        document.documentElement.setAttribute("lang", val.toUpperCase());
      }
    },
  },
  computed: {
    language: {
      get() {
        return this.$route.params.language != undefined ? this.$route.params.language : this.defaultLanguage;
      },
      set(val) {
        if (this.language != val) {
          this.$router.push(`/${val}`);
        }
      },
    },
    defaultTexts() {
      let result = this.$store.getters["liApiContent/getCollection"](`default_texts_${this.language}`);
      if (result != undefined && result != null && result != "") {
        
        let block = JSON.parse(result[0].content);
        if (block.type === "html") {
          result = JSON.parse(block.html);
        }
      }
      return result;
    },
  },
  methods: {
    liStripString(string){
      return string.trim()
          .toLowerCase()
          .replace(/[^\w\s-]/g, "")
          .replace(/^-+|-+$/g, "");
          // .replace(/[\s_]+/g, "_")
    },
    liBlockStyle(collection) {
      return Object.entries(collection)
        .map(([k, v]) => (v != "" && v != null ? `${this.liCamelToAttribute(k)}:${v};` : ""))
        .join("");
    },
    liBlockClasses(collection) {
      let classes = collection.styleClasses;
      classes = collection.classes.horizontalAlign != undefined && collection.classes.horizontalAlign != false ? `${classes} ${collection.classes.horizontalAlign}` : classes;
      classes = collection.classes.verticalAlign != undefined && collection.classes.verticalAlign != false ? `${classes} ${collection.classes.verticalAlign}` : classes;
      return classes;
    },
    liCamelToAttribute(str) {
      return str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
    },
    liGetNavigator() {
      if (window.navigator.platform.indexOf("Mac") > -1) {
        this.isWinOrMac = "Mac";
      }
      if (window.navigator.platform.indexOf("Win") > -1) {
        this.isWinOrMac = "Win";
      }
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    fetchCollection(_data) {
      return this.$store.dispatch("liApiContent/GET_COLLECTION", {
        request: {
          object: _data.object,
          where: _data.where,
          orderBy: _data.orderBy,
          select: _data.select
        },
        collectionName: `${_data.name}_${this.language}`,
      });
    },
    fetchURL(ignoreLast = false) {
      if (ignoreLast) {
        this.fullUrl = this.$route.path.substring(0, this.$route.path.lastIndexOf("/"));
      } else {
        this.fullUrl = this.$route.path == "/" ? `/${this.defaultLanguage}` : this.$route.path;
      }
    },
    fetchNavigationItems() {
      this.$store.dispatch("liApiContent/GET_COLLECTION", {
        request: {
          object: "li_static_blocks",
          where: `status [=>] 1 || language [=>] ${this.language} || block_identifier [=>] main_navigation || website [=>] ${this.website}`,
          orderBy: "sequence ASC",
          select: "content",
        },
        collectionName: `main_navigation_${this.language}`,
      });
    },
    fetchAppDefaultTexts() {
      if (this.language != null) {
        this.$store.dispatch("liApiContent/GET_COLLECTION", {
          request: {
            object: this.staticBlocksObject,
            where: `status [=>] 1 || language [=>] ${this.language} || block_identifier [=>] default_texts || website [=>] ${this.website}`,
            orderBy: "sequence ASC",
            select: "content",
          },
          collectionName: `default_texts_${this.language}`,
        });
      }
    },
    fetchPageData() {
      if (this.fullUrl != null) {
        this.$store.dispatch("liApiContent/GET_PAGE", {
          object: this.pageObject,
          where: `status [=>] 1 || language [=>] ${this.language} || fullurl [=>] ${this.fullUrl} || website [=>] ${this.website}`,
          orderBy: "sequence ASC",
          select: "pagetitle,pagedescription,content,language,fullurl,name",
        });
      }
    },
    getRandomFromArray(arr, n) {
      var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
      if (n > len) throw new RangeError("getRandom: more elements taken than available");
      while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
      }
      return result;
    },
    share(location) {
      let link = "";
      let url = window.location.href;
      if (location == "fb") {
        link = `https://www.facebook.com/sharer.php?u=${url}`;
      }
      if (location == "tw") {
        link = `https://twitter.com/intent/tweet?url=${url}`;
      }
      if (location == "li") {
        link = `https://www.linkedin.com/cws/share/?url=${url}`;
      }
      window.open(link);
    },
    openUrl(url, target = "_blank") {
      window.open(url, target);
    },
    onResize() {
      this.isAppMobile = window.innerWidth < 600;
      this.isAppTablet = window.innerWidth > 600 && window.innerWidth < 960;
      this.isAppDesktop = window.innerWidth > 960;
    },
    matchesOR(_item, _criteria) {
      for (var n = 0; n < _criteria.length; n++) {
        if (_criteria[n]["Values"].indexOf(_item[_criteria[n]["Field"]]) > -1) {
          return true;
        }
      }
    },
    matchesAND(_item, _criteria) {
      let count = 0;
      for (var n = 0; n < _criteria.length; n++) {
        if (_criteria[n]["Field"].includes(".")) {
          // MULTI LEVEL INDEX SEARCH -> should only return a string ..... SHOUD
          const value = this.pathIndex(_item, _criteria[n]["Field"]);
          if (typeof value === "string") {
            if (_criteria[n]["Values"].includes(value)) {
              count++;
            }
          }
        } else {
          // value is string
          if (typeof _item[_criteria[n]["Field"]] === "string") {
            if (_criteria[n]["Values"].indexOf(_item[_criteria[n]["Field"]]) > -1) {
              count++;
            }
          }
          // value is object
          if (typeof _item[_criteria[n]["Field"]] === "object") {
            const found = _item[_criteria[n]["Field"]].some((r) => _criteria[n]["Values"].includes(r));
            if (found) {
              count++;
            }
          }
        }
      }
      return count == _criteria.length;
    },
    arrayFilter(_data, _criteria, _type) {
      let matches = [];
      for (var i = 0; i < _data.length; i++) {
        // Determine if the current item matches the filter criteria
        if (_type == "OR") {
          if (this.matchesOR(_data[i], _criteria)) {
            matches.push(_data[i]);
          }
        } else if (_type == "AND") {
          if (this.matchesAND(_data[i], _criteria)) {
            matches.push(_data[i]);
          }
        }
      }
      return matches;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    deCapitalizeFirstLetter(string) {
      return string.charAt(0).toLowerCase() + string.slice(1);
    },
  },
};

<template>
  <v-container class="w-100 liContent liPage home" fluid>
    <template v-if="pageContent != null">
      <template v-for="(block, index) in pageContent">
        <component :is="`liBlock-${block.blockIdentifier}`" :block="block" :key="index" />
      </template>
    </template>
    <template v-else>
      <div class="text-center w-100 d-flex" style="height: calc(100vh - 25px)">
        <v-progress-circular indeterminate color="primary" class="ma-auto" />
      </div>
    </template>
  </v-container>
</template>

<script>
const API_URL = process.env.VUE_APP_CONTENT_API_URL;
export default {
  title: "Home",
  name: "Home",
  data() {
    return {
      apiPublicUrl: API_URL,
    };
  },
  created() {
    this.fetchURL();
  },
  watch: {
    page(val) {
      if (val != null) {
        this.$title = this.page.pagetitle;
      }
    },
  },
  mounted() {
    // if (this.debug) {
    //   console.log("START -- HOME VIEW");
    //   console.log("language", this.language);
    //   console.log("website", this.website);
    //   console.log("fullUrl", this.fullUrl);
    //   console.log("END -- HOME VIEW");
    // }
    this.fetchPageData();
  },
  computed: {
    page() {
      return this.$store.getters["liApiContent/getPage"](this.fullUrl);
    },
    pageContent() {
      return this.page != null ? JSON.parse(this.page.content) : null;
    },
  },
  methods: {},
};
</script>

<template>
  <div class="liMessager" :class="styleClasses">
    {{ message }}
  </div>
</template>
<script>
const API_URL_PUBLIC = process.env.VUE_APP_CONTENT_FILES_URL;
export default {
  name: "liMessager",
  data: () => ({
    apiPublicPath: API_URL_PUBLIC,
    m: {
      codeletters: "&#*1234567890LINNOX+%?£@§$",
      message: 0,
      current_length: 0,
      fadeBuffer: false,
    },
    message: "",
  }),
  props: {
    messages: Array,
    styleClasses: null,
  },
  mounted() {
    this.animateIn();
  },
  watch: {},
  computed: {},
  methods: {
    generateRandomString(length) {
      let random_text = "";
      while (random_text.length < length) {
        random_text += this.m.codeletters.charAt(Math.floor(Math.random() * this.m.codeletters.length));
      }
      return random_text;
    },
    animateIn() {
      if (this.m.current_length < this.messages[this.m.message].length) {
        this.m.current_length = this.m.current_length + 2;
        if (this.m.current_length > this.messages[this.m.message].length) {
          this.m.current_length = this.messages[this.m.message].length;
        }

        this.message = this.generateRandomString(this.m.current_length);

        setTimeout(this.animateIn, 20);
      } else {
        setTimeout(this.animateFadeBuffer, 20);
      }
    },
    animateFadeBuffer() {
      if (this.m.fadeBuffer === false) {
        this.m.fadeBuffer = [];
        for (var j = 0; j < this.messages[this.m.message].length; j++) {
          this.m.fadeBuffer.push({ c: Math.floor(Math.random() * 12) + 1, l: this.messages[this.m.message].charAt(j) });
        }
      }
      var do_cycles = false;
      this.message = "";
      for (var i = 0; i < this.m.fadeBuffer.length; i++) {
        var fader = this.m.fadeBuffer[i];
        if (fader.c > 0) {
          do_cycles = true;
          fader.c--;
          this.message += this.m.codeletters.charAt(Math.floor(Math.random() * this.m.codeletters.length));
        } else {
          this.message += fader.l;
        }
      }

      if (do_cycles === true) {
        setTimeout(this.animateFadeBuffer, 50);
      } else {
        setTimeout(this.cycleText, 2000);
      }
    },
    cycleText() {
      this.m.message = this.m.message + 1;
      if (this.m.message >= this.messages.length) {
        this.m.message = 0;
      }

      this.m.current_length = 0;
      this.m.fadeBuffer = false;
      this.message = "";

      setTimeout(this.animateIn, 200);
    },
  },
};
</script>

<template>
  <v-footer class="liFooter" padless v-if="footerContent != null && footerContent.length != 0">
    <v-row justify="center" no-gutters class="py-5">
      <v-container no-gutters class="mb-10">
        <v-col class="mx-auto pt-10 pb-5 text-center text-caption white--text" cols="12" md="8">
          <div class="py-4 w-100 text-h6" v-html="footerContent.footerText" />
        </v-col>
        <v-col class="pb-5 mb-10 text-center text-caption white--text" cols="12">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn :href="footerContent.emailBtnLink" color="" class="ma-2" fab v-bind="attrs" v-on="on">
                <v-icon small>mdi-email-outline</v-icon>
              </v-btn>
            </template>
            <span>
              {{ footerContent.emailBtnLabel }}
            </span>
          </v-tooltip>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn :href="footerContent.telephoneBtnLink" color="" class="ma-2" fab v-bind="attrs" v-on="on">
                <v-icon small>mdi-cellphone</v-icon>
              </v-btn>
            </template>
            <span>
              {{ footerContent.telephoneBtnLabel }}
            </span>
          </v-tooltip>
        </v-col>
        <v-col class="pb-5 mb-10 text-center text-caption white--text" cols="12">
          <strong>&copy;</strong>
          {{ new Date().getFullYear() }} —
          <strong>{{ footerContent.copyright }}</strong>
        </v-col>
      </v-container>
    </v-row>
    <v-btn v-scroll="onScroll" v-show="fab" fab dark fixed bottom right color="primary" @click="toTop">
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
  </v-footer>
</template>
<script>
export default {
  name: "liFooter",
  data: () => ({
    fab:false,
  }),
  mounted() {
    this.fetchFooterItems();
  },
  watch: {
    language() {
      this.fetchFooterItems();
    },
  },
  computed: {
    footerItems() {
      return this.$store.getters["liApiContent/getCollection"](`main_footer_${this.language}`);
    },
    footerContent() {
      let result = null;
      if (this.footerItems != null && this.footerItems != "") {
        let block = JSON.parse(this.footerItems[0].content);
        if (block.type === "html") {
          result = JSON.parse(block.html);
        }
      }
      return result;
    },
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 300;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    fetchFooterItems() {
      this.$store.dispatch("liApiContent/GET_COLLECTION", {
        request: {
          object: "li_static_blocks",
          where: `status [=>] 1 || language [=>] ${this.language} || block_identifier [=>] footer || website [=>] ${this.website}`,
          orderBy: "sequence ASC",
          select: "content",
        },
        collectionName: `main_footer_${this.language}`,
      });
    },
  },
};
</script>

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:language?",
    name: "Home",
    component: Home,
  },
  {
    path: "/:language/page/:uri",
    name: "page",
    component: () =>
      import(/* webpackChunkName: "Page" */ "../views/liPage.vue"),
  },
  {
    path: "/:language/item/:uri",
    name: "item",
    component: () =>
      import(/* webpackChunkName: "Item" */ "../views/liItem.vue"),
  },
  {
    path: "*",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "PageNotFound" */ "../views/PageNotFound.vue"),
  },

];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

export default router;

import axios from "axios";
const API_URL = process.env.VUE_APP_CONTENT_API_URL;
class liApiContent {
  getPage(_data) {
    const baseURI = `${API_URL}/collection/getCollection`;
    const body = _data;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(baseURI, body, config)
      .then(function (response) {
        // console.log(response);
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        console.debug(error);
      });
  }
  getCollection(_data) {
    const baseURI = `${API_URL}/collection/getCollection`;
    const body = _data;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(baseURI, body, config)
      .then(function (response) {
        // console.log(response);
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        console.debug(error);
      });
  }
}
export default new liApiContent();

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

// PAGE TITLE 
import VuePageTitle from 'vue-page-title'

Vue.use(VuePageTitle, {
  // prefix: 'My App - ',
  suffix: ' | Portfolio |  Dennis van Tol '
});


// LINNOX MIXINS
import liMixin from './mixins/liMixin';
Vue.mixin(liMixin)
// STATIC COMPONENTS
// NAVIGATION
import liNav from './components/structure/nav';
Vue.component('liNav', liNav);
// FOOTER
import liFooter from './components/structure/footer';
Vue.component('liFooter', liFooter);

// LINNOX COMPONENTS
import liMessager from './components/linnox/liMessager';
Vue.component('liMessager', liMessager);

// DYNAMIC COMPONENTS
// PLAIN TEXT
import liBlockPlainText from './components/dynamic/blocks/liBlock-plainText';
Vue.component('liBlock-plainText', liBlockPlainText);
// RICH TEXT
import liBlockRichText from './components/dynamic/blocks/liBlock-richText';
Vue.component('liBlock-richText', liBlockRichText);
// HERO TEXT ONLY
import liBlockHeroTextOnly from './components/dynamic/blocks/liBlock-heroTextOnly';
Vue.component('liBlock-heroTextOnly', liBlockHeroTextOnly);
// HERO TEXT ONLY
import liBlockHeroMatrix from './components/dynamic/blocks/liBlock-heroMatrix';
Vue.component('liBlock-heroMatrix', liBlockHeroMatrix);
// IMAGE AND TEXT
import liBlockImageAndText from './components/dynamic/blocks/liBlock-imageAndText';
Vue.component('liBlock-image-text', liBlockImageAndText);
// IMAGE AND TEXT
import liBlockTextAndImage from './components/dynamic/blocks/liBlock-textAndImage';
Vue.component('liBlock-text-image', liBlockTextAndImage);
// IMAGE
import liBlockImage from './components/dynamic/blocks/liBlock-image';
Vue.component('liBlock-image', liBlockImage);
// IMAGE AND TEXT OVERLAY
import liBlockImageAndTextOverlay from './components/dynamic/blocks/liBlock-imageAndTextOverlay';
Vue.component('liBlock-image-text-overlay', liBlockImageAndTextOverlay);
// PERSON IMAGE TEXT AND BUTTONS
import liBlockpersonImageTextAndButton from './components/dynamic/blocks/liBlock-personImageTextAndButton';
Vue.component('liBlock-person-image-text', liBlockpersonImageTextAndButton);
// COLLECTION
import liBlockImageCollection from './components/dynamic/blocks/liBlock-image-collection';
Vue.component('liBlock-images-collection', liBlockImageCollection);
// JSON 
import liBlockJSON from './components/dynamic/blocks/liBlock-JSON';
Vue.component('liBlock-JSON', liBlockJSON);


// LINNOX FILTERS
import './filters/liFilters';

// Perfect ScrollBar
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
Vue.use(PerfectScrollbar)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <div :id="block.design.identifier" class="liBlock JSON">
    <!-- <pre>
    {{ block }}
    </pre> -->
  </div>
</template>
<script>
export default {
  name: "liBlock-JSON",
  data: () => ({}),
  props: {
    block: Object,
  },
  mounted() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>

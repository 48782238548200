<template>
  <div :id="block.design.identifier" class="liBlock heroMatrix" :class="liBlockClasses(block.design)" :style="liBlockStyle(block.design.inlineStyles)">
    <div class="liHeroText" :class="block.design.styleClassesInner" v-html="block.value.heroMatrixText" />
    <canvas class="liMatrix" ref="matrix" :height="height" :width="width"></canvas>
  </div>
</template>
<script>
export default {
  name: "liBlock-heroMatrix",
  data: () => ({
    height: null,
    width: null,
    animationInterval: null,
    matrix: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ123456789@#$%^&*()*&^%+-/~{[|`]}",
    fontSize: 10,
    drops: [],
    c: null,
    ctx: null
  }),
  props: {
    block: Object,
  },
  computed: {
    randomTextColor() {
      return this.block.value.textColor == "" ? this.generateRandomColor() : this.block.value.textColor;
    },
    columns() {
      return this.width / this.fontSize;
    },
    matrixCharacters() {
      return this.matrix.split("");
    },
  },
  mounted() {
    this.onResizeHeroMatrix();
    window.addEventListener("resize", this.onResizeHeroMatrix);
    if (this.height != null && this.width != null) {
      this.c = this.$refs["matrix"];
      this.ctx = this.c.getContext("2d");
    }
  },
  methods: {
    draw() {
      // Black BG for the canvas translucent BG to show trail
      this.ctx.fillStyle = "rgba(0, 0, 0, 0.04)";
      this.ctx.fillRect(0, 0, this.width, this.height);
      // Text Color
      this.ctx.fillStyle = this.randomTextColor;
      // Font style
      this.ctx.font = this.fontSize + "px arial";
      // Looping over drops
      for (let i = 0; i < this.drops.length; i++) {
        // A random chinese character to print
        const text = this.matrixCharacters[Math.floor(Math.random() * this.matrixCharacters.length)];
        // X = i*font_size, y = value of drops[i]*font_size
        this.ctx.fillText(text, i * this.fontSize, this.drops[i] * this.fontSize);
        // Sending the drop back to the top randomly after it has crossed the screen
        // Adding a randomness to the reset to make the drops scattered on the Y axis
        if (this.drops[i] * this.fontSize > this.height && Math.random() > 0.975) this.drops[i] = 0;
        // Incrementing Y coordinate
        this.drops[i]++;
      }
    },
    onResizeHeroMatrix() {
      this.height = window.innerHeight / 1.3;
      this.width = window.innerWidth;
      for (var x = 0; x < this.columns; x++) this.drops[x] = 1;
      if (this.animationInterval != null) {
        clearInterval(this.animationInterval);
        this.animationInterval = setInterval(this.draw, 20);
      } else {
        this.animationInterval = setInterval(this.draw, 20);
      }
    },
    generateRandomColor() {
      let maxVal = 0xffffff; // 16777215
      let randomNumber = Math.random() * maxVal;
          randomNumber = Math.floor(randomNumber);
          randomNumber = randomNumber.toString(16);
      let randColor = randomNumber.padStart(6, 0);
      return `#${randColor.toUpperCase()}`;
    },
  }
};
</script>

<template>
  <div :id="block.design.identifier" class="liBlock image-text" :class="liBlockClasses(block.design)" :style="liBlockStyle(block.design.inlineStyles)">
    <div class="image" :class="block.value.imageClasses">
      <v-img  :aspect-ratio="block.value.aspectRatio" :src="`${apiPublicPath}${block.value.image}`" />
    </div>
    <div class="text" :class="block.value.richTextClasses">
      <div class="inner" v-html="block.value.richText" :class="block.value.richTextClassesInner"/>
    </div>
  </div>
</template>
<script>
const API_URL_PUBLIC = process.env.VUE_APP_CONTENT_FILES_URL;
export default {
  name: "liBlock-imageAndText",
  data: () => ({
    apiPublicPath: API_URL_PUBLIC,
  }),
  props: {
    block: Object,
  },
  mounted() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>
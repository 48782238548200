var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"liBlock personImageTextAndButton",class:_vm.liBlockClasses(_vm.block.design),style:(_vm.liBlockStyle(_vm.block.design.inlineStyles)),attrs:{"id":_vm.block.design.identifier}},[_c('div',{staticClass:"image",class:_vm.block.value.imageClasses},[_c('v-img',{attrs:{"aspect-ratio":_vm.block.value.aspectRatio,"src":("" + _vm.apiPublicPath + (_vm.block.value.image))}})],1),_c('div',{staticClass:"text",class:_vm.block.value.imageTextAndButtonClasses},[_c('div',{staticClass:"inner"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.block.value.imageTextAndButton)}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"href":_vm.block.value.resumeBtnLink,"color":"primary","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-pdf-box")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.block.value.resumeBtnLabel)+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"href":_vm.block.value.emailBtnLink,"color":"primary","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-email-outline")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.block.value.emailBtnLabel)+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"href":_vm.block.value.telephoneBtnLink,"color":"primary","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-cellphone")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.block.value.telephoneBtnLabel)+" ")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-app v-if="defaultTexts != null" :class="{ liMobile: this.isAppMobile, liTablet: this.isAppTablet, liDesktop: this.isAppDesktop }">
    <liNav />
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view :key="`${$route.name}-${$route.params.language}`" />
      </transition>
    </v-main>
    <liFooter />
  </v-app>
</template>
<script>
export default {
  name: "liApp",
  data: () => ({}),
  mounted() {
    if (this.defaultTexts == null) {
      this.fetchAppDefaultTexts();
    }
  },
  watch: {
    language() {
      this.fetchNavigationItems();
      this.fetchAppDefaultTexts();
    },
  },
  computed: {},
};
</script>
<style lang="scss">
@import "./scss/index.scss";
</style>

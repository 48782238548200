<template>
  <div :id="block.design.identifier" class="liBlock heroTextOnly" :class="liBlockClasses(block.design)" :style="liBlockStyle(block.design.inlineStyles)">
    <div class="liHeroText" :class="block.design.styleClassesInner" v-html="block.value.richTextHero" />
  </div>
</template>
<script>
export default {
  name: "liBlock-heroTextOnly",
  data: () => ({}),
  props: {
    block: Object,
  },
  mounted() {
    
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>

<template>
  <div :id="block.design.identifier" class="liBlock richText" :class="liBlockClasses(block.design)" :style="liBlockStyle(block.design.inlineStyles)">
    <div :class="block.design.styleClassesInner" v-html="block.value.richText"/>
  </div>
</template>
<script>
export default {
  name: "liBlock-richText",
  data: () => ({}),
  props: {
    block: Object,
  },
  mounted() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>

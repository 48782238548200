<template>
  <div :id="block.design.identifier" class="liBlock imageCollection" :class="liBlockClasses(block.design)" :style="liBlockStyle(block.design.inlineStyles)">
    <template v-if="collection != null && collection.length > 0">
      <template v-for="(item, index) in collection">
        <article :key="index" :class="computeArticleCLass(item)" ref="article3D" v-if="(isAppMobile && Number(item.mobile) == 1) || isAppTablet && Number(item.desktop) == 1 || isAppDesktop && Number(item.desktop) == 1">
          <v-img :src="`${apiPublicPath}${item[block.value.imageAttribute]}`" />
        </article>
      </template>
    </template>
  </div>
</template>
<script>
const API_URL_PUBLIC = process.env.VUE_APP_CONTENT_FILES_URL;
export default {
  name: "liBlock-collection",
  data: () => ({
    apiPublicPath: API_URL_PUBLIC,
  }),
  props: {
    block: Object,
  },
  mounted() {
    this.fetchCollection(this.block.value);
    
  },
  watch: {
  },
  computed: {
    collection: {
      get() {
        return this.$store.getters["liApiContent/getCollection"](`${this.block.value.name}_${this.language}`);
      },
      set(val) {
        console.log(val);
      },
    },
  },
  methods: {
    computeArticleCLass(item) {
      let _class = this.block.value.itemClass != "" ? this.block.value.itemClass : "";
      _class = this.block.value.itemClassAttribute != "" ? `${_class} ${this.block.value.itemClassAttributePrefix}${this.liStripString(item[this.block.value.itemClassAttribute])}` : `${_class}`;

      return _class;
    },
  },
};
</script>

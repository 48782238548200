<template>
  <div :id="block.design.identifier" class="liBlock personImageTextAndButton" :class="liBlockClasses(block.design)" :style="liBlockStyle(block.design.inlineStyles)">
    <div class="image" :class="block.value.imageClasses">
      <v-img :aspect-ratio="block.value.aspectRatio" :src="`${apiPublicPath}${block.value.image}`" />
    </div>
    <div class="text" :class="block.value.imageTextAndButtonClasses">
      <div class="inner">
        <span v-html="block.value.imageTextAndButton" />
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn :href="block.value.resumeBtnLink" color="primary" class="ma-2" fab v-bind="attrs" v-on="on">
              <v-icon >mdi-file-pdf-box</v-icon>
            </v-btn>
          </template>
          <span>
            {{ block.value.resumeBtnLabel }}
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn :href="block.value.emailBtnLink" color="primary" class="ma-2" fab v-bind="attrs" v-on="on">
              <v-icon small>mdi-email-outline</v-icon>
            </v-btn>
          </template>
          <span>
            {{ block.value.emailBtnLabel }}
          </span>
        </v-tooltip>
        <!-- <v-btn :href="block.value.resumeBtnLink" color="black" target="_blank" class="white--text ma-2">
          {{ block.value.resumeBtnLabel }}
        </v-btn> -->
        <!-- <v-btn :href="block.value.emailBtnLink" color="black" class="white--text ma-2">
          {{ block.value.emailBtnLabel }}
        </v-btn> -->
        <!-- <v-btn :href="block.value.telephoneBtnLink" color="black" class="white--text ma-2">
          {{ block.value.telephoneBtnLabel }}
        </v-btn> -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn :href="block.value.telephoneBtnLink" color="primary" class="ma-2" fab v-bind="attrs" v-on="on">
              <v-icon small>mdi-cellphone</v-icon>
            </v-btn>
          </template>
          <span>
            {{ block.value.telephoneBtnLabel }}
          </span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>
<script>
const API_URL_PUBLIC = process.env.VUE_APP_CONTENT_FILES_URL;
export default {
  name: "liBlock-personImageTextAndButton",
  data: () => ({
    apiPublicPath: API_URL_PUBLIC,
  }),
  props: {
    block: Object,
  },
  mounted() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>

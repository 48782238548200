import liApiContentService from "../services/liApiContent.service";
export const liApiContent = {
  namespaced: true,
  state: {
    pages: {},
    blocks: {},
    collections: {}
  },
  actions: {
    GET_PAGE({ commit }, _data) {
      return liApiContentService.getPage(_data).then(
        (response) => {
          commit("setPage", {
            page: response.payload[0],
            url: response.payload[0].fullurl,
          });
          return Promise.resolve(response.payload[0]);
        },
        (error) => {
          console.debug(error);
        }
      );
    },
    GET_COLLECTION({ commit }, _data) {
      return liApiContentService.getCollection(_data.request).then(
        (response) => {
          commit("setCollection", {
            collection: response.payload,
            collectionName: _data.collectionName,
          });
          return Promise.resolve(response.payload);
        },
        (error) => {
          console.debug(error);
        }
      );
    },
  },
  mutations: {
    emptyFN() {
      // SILENCE IS GOLDEN
    },
    setPage(state, _data) {
      state.pages = {
        ...state.pages,
        [_data.url]: _data.page,
      };
    },
    setCollection(state, _data) {
      state.collections = {
        ...state.collections,
        [_data.collectionName]: _data.collection,
      };
    },
  },
  getters: {
    getPage: (state) => (url) => {
      if (state.pages[url]) {
        return state.pages[url];
      } else {
        return null;
      }
    },
    getCollection: (state) => (collectionName) => {
      if (state.collections[collectionName]) {
        return state.collections[collectionName];
      } else {
        return null;
      }
    },
  },
};

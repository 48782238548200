<template>
  <v-img :id="block.design.identifier" class="liBlock image" :aspect-ratio="block.value.aspectRatio" :src="`${apiPublicPath}${block.value.image}`" :class="liBlockClasses(block.design)" :style="liBlockStyle(block.design.inlineStyles)"/>
</template>
<script>
const API_URL_PUBLIC = process.env.VUE_APP_CONTENT_FILES_URL;
export default {
  name: "liBlock-image",
  data: () => ({
    apiPublicPath: API_URL_PUBLIC,
  }),
  props: {
    block: Object,
  },
  mounted() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>

<template>
  <div :id="block.design.identifier" class="liBlock image-text-overlay" :class="liBlockClasses(block.design)" :style="liBlockStyle(block.design.inlineStyles)">
    <v-img class="image" :aspect-ratio="block.value.aspectRatio" :src="`${apiPublicPath}${block.value.image}`" :class="block.value.imageClasses">
      <v-row class="fill-height pa-0 ma-0" align="center" justify="center">
          <div class="text" v-html="block.value.richTextImageAndText" :class="block.value.richTextImageAndTextClasses" />
      </v-row>
    </v-img>
  </div>
</template>
<script>
const API_URL_PUBLIC = process.env.VUE_APP_CONTENT_FILES_URL;
export default {
  name: "liBlock-imageAndText",
  data: () => ({
    apiPublicPath: API_URL_PUBLIC,
  }),
  props: {
    block: Object,
  },
  mounted() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>

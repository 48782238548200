<template>
  <v-app-bar fixed dark :elevation="scrollPosition > 10 ? 5 : 0" class="liNav" :class="{ scrolled: scrollPosition > 10 }">
    <!-- START / HOME BUTTON / LOGO -->
    <v-btn key="home-desktop" text class="liLogo pa-0 desktop" :class="defaultTexts.logo.desktop.btnClasses" :to="`/${$route.params.language != undefined ? $route.params.language : ''}`" v-if="!isAppMobile">
      <v-img :width="scrollPosition > 300 ? defaultTexts.logo.desktop.widthScrolled : defaultTexts.logo.desktop.width" :src="logoImageWhite" :class="defaultTexts.logo.desktop.logoClasses" />
    </v-btn>
    <v-btn key="home-mobile" text class="liLogo pa-0 mobile" :class="defaultTexts.logo.mobile.logoClasses" :to="`/${$route.params.language != undefined ? $route.params.language : ''}`" v-else >
      <v-img :width="scrollPosition > 300 ? defaultTexts.logo.mobile.widthScrolled : defaultTexts.logo.mobile.width" :src="logoImageWhite" />
    </v-btn>
    <div>
      <span class="ml-1 ml-md-5 text-h6 mb-0 pb-0 ">
        {{ defaultTexts.headerTitle }}
      </span>
      <liMessager :messages="defaultTexts.animationText" :styleClasses="'ml-1 ml-md-5'" />
    </div>
    <!-- EMD / HOME BUTTON / LOGO -->
    <v-spacer />
    <!-- START / NAVIGATION / DESKTOP
    <template v-if="!isAppMobile && navigationItems != null">
      <template v-for="(nav, index) in navigationItems">
        <v-btn v-if="nav.status != 0" :key="index" text class="" :to="nav.url">
          {{ nav.title }}
        </v-btn>
      </template>
    </template>
     -->
    <!-- START / NAVIGATION ONE PAGE / DESKTOP -->
    <template v-if="!isAppMobile && navigationItems != null">
      <template v-for="(nav, index) in navigationItems">
        <v-btn v-if="nav.status != 0" text :key="index" color="light" class="mx-1" @click="scrollTo(nav)" :class="nav.styleClasses">
          {{ nav.label }}
        </v-btn>
      </template>
    </template>

    <!-- Language selector -->
    <v-menu bottom left v-if="defaultTexts != null && defaultTexts.languageSelector != undefined">
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark v-bind="attrs" v-on="on" style="padding: 0px; max-width: 22px; height: unset; min-width: unset" class="bg-black">
          <v-img v-if="language != null" :src="flags[language]" :width="isAppMobile ? '25px' : '35px'" />
          <v-img v-else :src="flags['nl']" :width="isAppMobile ? '25px' : '35px'" />
        </v-btn>
      </template>
      <v-list dense>
        <template v-for="(item, i) in defaultTexts.languageSelector.languages">
          <v-list-item
            v-if="item.status == 1"
            :key="i"
            :link="language != item.shortcode"
            :disabled="language == item.shortcode"
            :style="language == item.shortcode ? 'background-color:#f1f1f1;' : ''"
          >
            <v-list-item-title @click.prevent="setLanguage(item.shortcode)">
              <v-img :src="flags[item.shortcode]" width="20px" height="15px;" style="display: inline-block; margin-right: 10px" />
              {{ item.name }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import logoBlack from "../../assets/images/logo-dvt-black.svg";
import logoWhite from "../../assets/images/logo-dvt-white.svg";

export default {
  name: "liNav",
  data() {
    return {
      logoImageBlack: logoBlack,
      logoImageWhite: logoWhite,
      onePageNavoptions: {
        duration: 300,
        offset: 0,
        easing: "easeInOutCubic",
      },
    };
  },
  created() {
    this.fetchNavigationItems();
  },
  computed: {
    
    navigationItems() {
      let result = this.$store.getters["liApiContent/getCollection"](`main_navigation_${this.language}`);
      if (result != null && result != "") {
        let block = JSON.parse(result[0].content);
        if (block.type === "html") {
          result = JSON.parse(block.html);
        }
      }
      return result;
    },
  },
  mounted() {},
  methods: {
    scrollTo(nav){
      console.log(nav.to)
      // this.$vuetify.goTo(this.$refs[nav.to], this.onePageNavoptions)
      let elm = document.getElementById(nav.to);
      if(elm != null){
        this.$nextTick(() =>
          setTimeout(() => {
            elm.scrollIntoView({behavior: 'smooth'})
            
          }, 1000)
        );

      }
    },
    setLanguage(languageCode) {
      if (languageCode == "be_fr") {
        document.documentElement.setAttribute("lang", "fr");
      } else {
        document.documentElement.setAttribute("lang", this.languageCode);
      }
      this.$router.push(`/${languageCode}/home`);
    },
  },
};
</script>
